import React from "react";
import { Helmet } from "react-helmet";
import "./all.scss";
import useSiteMetadata from "./SiteMetadata";
import { Link, withPrefix } from "gatsby";

import Facebook from "../img/social/facebook.svg";
import Instagram from "../img/social/instagram.svg";
import Twitter from "../img/social/twitter.svg";
import Spotify from "../img/social/spotify.svg";
import YouTube from "../img/social/youtube.svg";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#000000" />

        <meta propert="og:site_name" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.png`}
        />
      </Helmet>
      <div className="min-vh-100">
        <div className="container">
          <div className="row">
            <div className="col">
              <header className="my-4">
                <Link to="/">
                  <img
                    className="img-fluid mx-auto d-block"
                    style={{ maxWidth: "300px", maxHeight: "30vh" }}
                    src={`${withPrefix("/")}img/CoralBendersLogo_2021_compressed.png`}
                    alt="Coral Benders"
                  />
                  <h1 className="visually-hidden">Coral Benders</h1>
                </Link>
              </header>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              <div className="d-flex align-items-center justify-content-center">
                <a
                  className="social-icon mx-2"
                  title="Facebook"
                  href="https://facebook.com/coralbenders"
                >
                  <Facebook />
                  <span className="visually-hidden">Facebook</span>
                </a>
                <a
                  className="social-icon mx-2"
                  title="Instagram"
                  href="https://instagram.com/coralbenders"
                >
                  <Instagram />
                  <span className="visually-hidden">Instagram</span>
                </a>
                <a
                  className="social-icon mx-2"
                  title="Twitter"
                  href="https://twitter.com/coralbendersdc"
                >
                  <Twitter />
                  <span className="visually-hidden">Twitter</span>
                </a>
                <a
                  className="social-icon mx-2"
                  title="YouTube"
                  href="https://www.youtube.com/channel/UC4fQ7ERcheH31wJaqMhUnrw"
                >
                  <YouTube />
                  <span className="visually-hidden">YouTube</span>
                </a>
                <a
                  className="social-icon mx-2"
                  title="Spotify"
                  href="https://open.spotify.com/artist/2DqZigYfHauT43Rt3g3CTl?si=ym1cLBXBT7u-3azR-Vsq0Q&dl_branch=1"
                >
                  <Spotify />
                  <span className="visually-hidden">Spotify</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default TemplateWrapper;
